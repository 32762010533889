.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  .form-wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    width: 400px;
    padding: 1.5rem;
    box-shadow: 0 16px 128px rgb(0 0 0 / 50%);
    .heading {
      font-family: "RussoOne-Regular";
      font-size: 1.7rem;
      margin-bottom: 1.5rem;
      :last-child {
        font-size: 1rem;
        font-weight: 200;
      }
    }
    .form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .create-action {
        width: 100%;
        padding: 10px 16px;
        background: rgba(66, 133, 244, 1);
        border-radius: 8px;
        border: 1px solid transparent;
        color: #ffffff;
        font-weight: 800;
        cursor: pointer;
        margin-top: 1rem;
        &:hover {
          background: rgba(66, 133, 244, 0.9);
        }
        &:focus {
          background: rgba(66, 133, 244, 1);
        }
        &:disabled {
          background: rgba(66, 133, 244, 0.5);
        }
      }
    }
  }
}

/** Form stylig */

.form-group {
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}

.form-field {
  width: 90%;
  padding: 10px 16px;
  background: rgba(243, 243, 244, 1);
  border-radius: 8px;
  border: 1px solid transparent;
  margin-bottom: 4px;
  &:hover {
    background: rgba(243, 243, 244, 0.5);
    border: 1px solid rgba(66, 133, 244, 0.5);
  }
  &:focus {
    background: #ffffff;
    // border: 1px solid rgba(66, 133, 244, 0.4);
    box-shadow: 0 0 0 0.2rem rgba(66, 133, 244, .25);
  }
}

.form-field-invalid {
  width: 90%;
  padding: 10px 16px;
  background: rgba(243, 243, 244, 1);
  border-radius: 8px;
  border: 0;
  margin-bottom: 4px;
  border: 1px solid rgba(234, 76, 137, 0.8);
  outline: none;
  &:hover {
    background: rgba(243, 243, 244, 0.5);
    border: 1px solid rgba(234, 76, 137, 0.5);
  }
  &:focus {
    background: #ffffff;
    border: 1px solid rgba(234, 76, 137, 0.8);
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, .25);
  }
}

.form-label {
  font-weight: 400;
  margin: 0 0 2px 4px;
  font: 0.8rem "RobotoSerif";
}

.info-field {
  width: 100%;
  margin-top: 4px;
  font: 0.6rem "RobotoSerif";
  color: #6c757d;
  margin: 0 0 2px 4px;
}

.required-field {
  width: 100%;
  margin-top: 4px;
  font: 0.6rem "RobotoSerif";
  color: rgba(234, 76, 137, 1);
  margin: 0 0 2px 4px;
}

.password {
  display: flex; 
  flex-direction: row;
  cursor: pointer;
  .eye {
    margin-left: 2px;
    display: flex; 
    border-radius: 0 8px 8px 0;
    border: 1px solid rgba(243, 243, 244, 1);
    margin-bottom: 4px;
    > div {
      margin: auto;
    }
  }
}

/** Set styling for mobile/portrait tablets devices */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .form-wrapper {
    width: 300px !important;
  }
}